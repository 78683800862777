<template>
<div>
  <a class="is-block is-flex" @click="downloadAttachment">
    <icon class="mr" :icon="downloading ? 'spinner spin' : 'download'"/>
    <span>{{ attachment.name }}</span>
  </a>
</div>
</template>
<script>
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  props: {
    attachment: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    downloading: false
  }),

  methods: {
    downloadAttachment() {
      this.downloading = true
      backend.downloadFile(`/v1/attachment/${this.attachment.id}/download`, ({data}) => {
        download(data, this.attachment.name, data.type);
        this.downloading = false
      }, () => {
        this.downloading = false
      })
    }
  }

}
</script>