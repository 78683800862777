<template>
<div>
  <attachment :attachment="attachment" v-for="attachment in attachments" :key="attachment.id" />
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { flatMap, concat } from 'lodash'
import Attachment from './Attachment.vue'

export default {

  components: {
    Attachment
  },

  computed: {
    ...mapGetters('support', [
      'ticket'
    ]),
    ticketAttachments() {
      return this.ticket.attachments
    },
    responseAttachments() {
      return flatMap(this.ticket.replies, reply => reply.attachments)
    },
    attachments() {
      return concat(
        this.ticketAttachments,
        this.responseAttachments
      )
    }
  }

}
</script>