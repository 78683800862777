<template>
<form>
  <columns>
    <column>
      <text-area
        required
        v-model="reply.message"
        :error="$root.errors.message">
        Reply
      </text-area>
    </column>
  </columns>
  <columns>
    <column>
      <file-input @select="updateAttachment">Attach a File</file-input>
    </column>
  </columns>
  <columns>
    <column>
      <submit-button
        :working="submitting"
        @submit="submitReply">
        Submit
      </submit-button>
    </column>
  </columns>
</form>
</template>
<script>
export default {

  data: () => ({
    submitting: false,
    reply: {
      message: '',
      attachment: null
    }
  }),

  methods: {
    updateAttachment(file) {
      this.reply.attachment = file
    },
    submitReply() {
      this.submitting = true
      this.$store.dispatch('support/addReply', this.reply).then(() => {
        this.submitting = false
        this.$toast.success('Reply sent')
        this.reply = {
          message: ''
        }
      }).catch(() => this.submitting = false)
    }
  }

}
</script>