<template>
<div>
  <columns>
    <column>
      <page-heading :heading="`#0000${ticket.number}`"></page-heading>
      <h5 class="subtitle is-5">{{ ticket.type.title }}, opened by {{ ticket.user.full_name }}</h5>
    </column>
    <div class="column is-narrow">
      <div class="buttons">
        <action-button v-if="!ticket.closed_at" @click="closeTicket">Close Request</action-button>
        <router-link exact class="button" :to="{ name: 'support' }">Back</router-link>
      </div>
    </div>
  </columns>
  <columns>
    <column>
      <div class="box" v-html="ticket.message"></div>

      <reply :reply="reply" v-for="reply in ticket.replies" :key="reply.id"/>

      <columns v-if="!ticket.closed_at">
        <column>
          <new-reply />
        </column>
      </columns>
    </column>
    <column class="is-3">
      <h4 class="title is-4">Attachments</h4>
      <attachments />
    </column>
  </columns>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import NewReply from './partials/NewReply.vue'
import Reply from './partials/Reply.vue'
import Attachments from './partials/Attachments.vue'

export default {

  components: {
    NewReply,
    Reply,
    Attachments
  },

  beforeCreate() {
    this.$store.dispatch('support/loadTicket', this.$route.params.ticket)
      .then(() => this.loading = false)
      .catch(() => this.loading = false)
  },

  beforeDestroy() {
    this.$store.commit('support/clearTicket')
  },

  methods: {
    async closeTicket() {
      if(await this.$confirm({
        title: 'Close Support Request',
        message: 'Are you sure you want to close this support request?'
      })) {
        this.$store.dispatch('support/closeTicket', this.ticket.id).then(() => {
          this.closing = false
          this.$toast.success('Ticket closed')
          this.$router.push({
            name: 'support'
          })
        }).catch(() => {
          this.closing = false
        })
      }
    }
  },

  computed: {
    ...mapGetters('support', [
      'ticket'
    ])
  }

}
</script>