<template>
<div class="box">
  <columns>
    <column class="is-narrow">
      <avatar :name="reply.user.full_name"/>
    </column>
    <column>
      <p>{{ reply.user.full_name }}, {{ reply.created_at | asCalendar }}</p>
      <div v-html="reply.message"></div>
    </column>
  </columns>
</div>
</template>
<script>
export default {

  props: {
    reply: {
      type: Object,
      default: () => ({})
    }
  }

}
</script>